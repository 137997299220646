<template>
  <div id="page">
    <h1>Recopick 이용 가이드</h1>
    <div>
      <p>
        <span class="confluence-embedded-file-wrapper">
          <img class="confluence-embedded-image" src="@/assets/images/img_home.png" data-image-src="/download/attachments/491526/img_home.png?version=2&amp;modificationDate=1444812891000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="4849689" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="img_home.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491526" data-linked-resource-container-version="57">
        </span>
      </p>
      <h2 id="Recopick이용가이드-Recopick이용가이드">
        <span style="color: rgb(0,51,102);">Recopick 이용 가이드&nbsp;</span>
      </h2>
      <ul>
        <li>
          <a href="/pages/viewpage.action?pageId=4718816">Recopick 설치 가이드</a>
            : 로그수집 스크립트 삽입부터 추천적용까지 단계별 가이드를 제시합니다.&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          <a href="/pages/viewpage.action?pageId=4718688">Recopick 추천 위치 가이드</a>
            : 사이트 영역별 추천 적용 위치, 적용 예시 등을 제시합니다.&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          <a href="/pages/viewpage.action?pageId=4718691">Recopick 추천 위젯 이용 안내</a>
            : 추천위젯툴 메뉴 설명 및 이용에 대한 안내를 확인하실 수 있습니다.
        </li>
      </ul>
      <ul>
        <li>
          <a href="https://blog.recopick.com/175" class="external-link">적용 고객사</a>
          : 현재 레코픽 추천을 사용하시는 고객사 실제 적용 모습을 확인하실 수 있습니다.&nbsp;</li>
      </ul>
      <ul>
        <li>
          <a href="/display/docs/APIs">APIs</a>
          : Recopick에서는 개발자 고객님들을 위한 API를 지원하고 있습니다!&nbsp;<br>
          <span style="line-height: 1.4285715;">&nbsp;- Recopick에서 제공하는 스크립트를 고객님의 취향에 맞게 수정하여 사용하실 수 있도록 Client Library API를 지원하고 있습니다. &nbsp;</span>
          <a href="/display/docs/Client+Library+APIs">Client Library APIs</a>
          <span style="line-height: 1.4285715;"> 문서를 참고하시면 좀더 자세한 내용을 보실 수 있습니다.</span><br>&nbsp;- 스크립트가 아닌 REST API형태로 고객님께서 운영하시는 사이트의 서버에서 직접 로그를 전송하거나, Recopick에서 추천 결과를 가져가거나, 상품 정보를 가져 가는 등의 API는&nbsp;
          <a href="/display/docs/2.1REST+APIs">REST APIs</a>
          문서를 참고 하시면 이용하실 수 있습니다.<br><br>
        </li>
        <li>
          <a href="/pages/viewpage.action?pageId=3244277">자주하는 질문</a>
        </li>
      </ul>
      <p><br></p></div>
  </div>
</template>